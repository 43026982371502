// ------------------------------------
// Primary Actions
// ------------------------------------
const showStep = definition => (dispatch, getState) => {
	const { redirectUrl } = definition

	window.location.replace(redirectUrl)
}

export const init = definition => (dispatch, getState) => {
	showStep(definition)(dispatch, getState)
}

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}
