import { formatMessage, getIsStudyRtl } from 'store/intl'
import { addMessage, addAction, addTempMessage } from 'store/queue'
import { addAccessibilityHintText, publishAccessibilityHint } from 'store/_chat/accessibilityHint'
import { setActionSubmitListener, showActionButton, showActionHint } from 'store/_chat/actionBar'
import { createComponent, removeTempMessages } from 'store/_chat/history'

import { ACTION_BUTTON_STATE } from 'constants/actionButton'
import { COMPONENTS, POSITION } from 'constants/component'
import {
	showInput,
	setInput,
	setInputChangeListener,
	validInput,
	invalidInput,
	getInputValue,
} from 'store/_chat/input'
import { INPUT_TYPE } from 'constants/input'
import { DELAY_LENGTH } from 'constants/delay'

import {
	getStudyObjectDefinition,
	getStudyObject,
	getIsAccessibilityEnabled,
} from 'selectors/study'

// ------------------------------------
// Actions
// ------------------------------------

// ------------------------------------
// Primary Actions
// ------------------------------------
export const init = module => (dispatch, getState) => {
	// acion bar setup
	setInputChangeListener(inputChangeListener)(dispatch, getState) // eslint-disable-line no-use-before-define
	setActionSubmitListener(primarySubmitListener, secondarySubmitListener)(dispatch, getState) // eslint-disable-line no-use-before-define

	/**
	 * removeTempMessages has 500ms animation timeout so we wait
	 * for it to finish before showing step that adds new temp messages
	 */
	setTimeout(() => {
		showStep(module)(dispatch, getState) // eslint-disable-line no-use-before-define
	}, DELAY_LENGTH.LONG)
}

export const showStep = module => (dispatch, getState) => {
	switch (module.subtype) {
		case INPUT_TYPE.TEXTAREA:
			handleTextAreaType(module)(dispatch, getState) // eslint-disable-line no-use-before-define
			break
		case INPUT_TYPE.INPUT:
			handleInputType(module)(dispatch, getState) // eslint-disable-line no-use-before-define
			break
		default:
			break
	}
	addAction(showInput)(dispatch, getState)
}

export const endStep = (data, messageText) => (dispatch, getState) => {
	removeTempMessages()(dispatch, getState)

	let studyObject = getStudyObject()(dispatch, getState)
	// show response message
	let message = createComponent(COMPONENTS.MESSAGE)
	message.props.text = messageText

	const messagePosition = getIsStudyRtl(getState()) === true ? POSITION.LEFT : POSITION.RIGHT

	addMessage(message, messagePosition)(dispatch, getState)
	// send & load next module
	let response = {}
	response[studyObject.id] = data
	getState().study.getNextStep(response, null, true)(dispatch, getState)
}

// ------------------------------------
// Listeners
// ------------------------------------

export const inputChangeListener = (input, options) => (dispatch, getState) => {
	let definition = getStudyObjectDefinition()(dispatch, getState)

	let testPattern = new RegExp(definition.validationPattern || '.*')
	let isValid = testPattern.test(input)

	if (input.trim().length > 0 && isValid) {
		handleValidInput(input)(dispatch, getState) // eslint-disable-line no-use-before-define
	} else {
		handleInvalidInput(input)(dispatch, getState) // eslint-disable-line no-use-before-define
	}
}

export const primarySubmitListener = () => (dispatch, getState) => {
	const definition = getStudyObjectDefinition()(dispatch, getState)
	const inputValue = getInputValue()(dispatch, getState)
	const trimmedInput = inputValue.trim()

	const isDecimalInput =
		['^-?\\d+((\\.|\\,)\\d+)?$', '^-?\\d+((\\.|\\,)\\d{1,2})?$'].includes(
			definition.validationPattern,
		) === true

	const formattedValue = isDecimalInput === true ? trimmedInput.replace(',', '.') : trimmedInput

	endStep(formattedValue, trimmedInput)(dispatch, getState)
}

export const secondarySubmitListener = () => (dispatch, getState) => {
	let messageText = formatMessage('general_skip_action_message')(dispatch, getState)

	endStep(null, messageText)(dispatch, getState)
}

// ------------------------------------
// Helper Actions
// ------------------------------------

export const handleTextAreaType = module => (dispatch, getState) => {
	let submitButtonText = formatMessage('action_input_submit_button_text')(dispatch, getState)
	let skipButtonText = formatMessage('action_input_skip_button_text')(dispatch, getState)

	addAction(setInput, [
		INPUT_TYPE.TEXTAREA,
		module.placeholder,
		module.maxLength,
		true,
		false,
		true,
		module.maxLength,
		true,
	])(dispatch, getState)
	if (module.mandatory === false) {
		if (getIsAccessibilityEnabled(getState()) === true) {
			addAccessibilityHintText(formatMessage('skip_enabled')(dispatch, getState))(
				dispatch,
				getState,
			)
			publishAccessibilityHint()(dispatch, getState)
		}

		addAction(showActionButton, [ACTION_BUTTON_STATE.SKIP_READY, skipButtonText])(
			dispatch,
			getState,
		)
	} else if (module.inputHint === null) {
		addAction(showActionButton, [ACTION_BUTTON_STATE.DEFAULT, submitButtonText])(dispatch, getState)
	} else {
		addAction(showActionHint, [module.inputHint])(dispatch, getState)

		if (getIsAccessibilityEnabled(getState()) === true) {
			const instruction = createComponent(COMPONENTS.INSTRUCTION)
			instruction.props.text = module.inputHint

			const solverMessagePosition =
				getIsStudyRtl(getState()) === true ? POSITION.RIGHT : POSITION.LEFT

			addTempMessage(instruction, solverMessagePosition, 0)(dispatch, getState)
		}
	}
}

export const handleInputType = module => (dispatch, getState) => {
	const submitButtonText = formatMessage('action_input_submit_button_text')(dispatch, getState)
	const skipButtonText = formatMessage('action_input_skip_button_text')(dispatch, getState)

	if (module.mandatory === false) {
		if (getIsAccessibilityEnabled(getState()) === true) {
			addAccessibilityHintText(formatMessage('skip_enabled')(dispatch, getState))(
				dispatch,
				getState,
			)
			publishAccessibilityHint()(dispatch, getState)
		}

		addAction(showActionButton, [ACTION_BUTTON_STATE.SKIP_READY, skipButtonText])(
			dispatch,
			getState,
		)
	} else {
		let hint = module.inputHint ? module.inputHint : submitButtonText

		addAction(showActionHint, [hint])(dispatch, getState)

		if (getIsAccessibilityEnabled(getState()) === true) {
			if (module.inputHint !== null) {
				const instruction = createComponent(COMPONENTS.INSTRUCTION)
				instruction.props.text = hint

				const solverMessagePosition =
					getIsStudyRtl(getState()) === true ? POSITION.RIGHT : POSITION.LEFT

				addTempMessage(instruction, solverMessagePosition, 0)(dispatch, getState)
			}
		}
	}

	addAction(setInput, [
		INPUT_TYPE.INPUT,
		module.placeholder,
		module.maxLength,
		true,
		true,
		false,
		'',
		true,
	])(dispatch, getState)
}

export const handleValidInput = input => (dispatch, getState) => {
	let definition = getStudyObjectDefinition()(dispatch, getState)
	let submitButtonText = formatMessage('action_input_submit_button_text')(dispatch, getState)

	const isAccessibilityEnabled = getIsAccessibilityEnabled(getState())

	if (definition.maxInputLength !== null) {
		let remainingChars = definition.maxInputLength - input.length

		if (remainingChars >= 0) {
			const remainingCharactersText = formatMessage('input_chars_left', { number: remainingChars })(
				dispatch,
				getState,
			)

			if (isAccessibilityEnabled === true) {
				addAccessibilityHintText(remainingCharactersText)(dispatch, getState)
				addAccessibilityHintText(formatMessage('submit_enabled')(dispatch, getState))(
					dispatch,
					getState,
				)
				publishAccessibilityHint()(dispatch, getState)
			}

			validInput(remainingCharactersText)(dispatch, getState)
			showActionButton(ACTION_BUTTON_STATE.SUBMIT_READY, submitButtonText)(dispatch, getState)
		} else {
			const tooLongTranslation = formatMessage('input_too_long', {
				number: Math.abs(remainingChars),
			})(dispatch, getState)

			invalidInput(tooLongTranslation)(dispatch, getState)

			let hintText = formatMessage('textarea_too_long_hint')(dispatch, getState)
			showActionHint(hintText)(dispatch, getState)

			if (isAccessibilityEnabled === true) {
				addAccessibilityHintText(tooLongTranslation)(dispatch, getState)
				publishAccessibilityHint()(dispatch, getState)
			}
		}
	} else {
		if (isAccessibilityEnabled === true) {
			addAccessibilityHintText(formatMessage('submit_enabled')(dispatch, getState))(
				dispatch,
				getState,
			)
			publishAccessibilityHint()(dispatch, getState)
		}

		validInput('')(dispatch, getState)
		showActionButton(ACTION_BUTTON_STATE.SUBMIT_READY, submitButtonText)(dispatch, getState)
	}
}

export const handleInvalidInput = input => (dispatch, getState) => {
	let definition = getStudyObjectDefinition()(dispatch, getState)
	let submitButtonText = formatMessage('action_input_submit_button_text')(dispatch, getState)
	let skipButtonText = formatMessage('action_input_skip_button_text')(dispatch, getState)

	const idTranslation = input.trim().length === 0 ? 'input_empty' : 'input_invalid'

	const invalidInputText = formatMessage(idTranslation)(dispatch, getState)

	const isAccessibilityEnabled = getIsAccessibilityEnabled(getState())

	if (definition.maxInputLength !== null) {
		let remainingChars = definition.maxInputLength - input.length

		if (remainingChars >= 0) {
			if (isAccessibilityEnabled === true) {
				addAccessibilityHintText(invalidInputText)(dispatch, getState)
				publishAccessibilityHint()(dispatch, getState)
			}

			invalidInput(invalidInputText)(dispatch, getState)
		} else {
			const tooLongTranslation = formatMessage('input_too_long', {
				number: Math.abs(remainingChars),
			})(dispatch, getState)

			if (isAccessibilityEnabled === true) {
				addAccessibilityHintText(tooLongTranslation)(dispatch, getState)
				publishAccessibilityHint()(dispatch, getState)
			}

			invalidInput(tooLongTranslation)(dispatch, getState)
		}
	} else {
		if (isAccessibilityEnabled === true) {
			addAccessibilityHintText(invalidInputText)(dispatch, getState)
			publishAccessibilityHint()(dispatch, getState)
		}

		invalidInput(invalidInputText)(dispatch, getState)
	}

	let hintText = definition.inputHint ? definition.inputHint : submitButtonText

	if (input.length === 0) {
		if (definition.mandatory) {
			showActionHint(hintText)(dispatch, getState)

			if (isAccessibilityEnabled === true) {
				addAccessibilityHintText(invalidInputText)(dispatch, getState)
				publishAccessibilityHint()(dispatch, getState)
			}
		} else {
			if (isAccessibilityEnabled === true) {
				addAccessibilityHintText(formatMessage('skip_enabled')(dispatch, getState))(
					dispatch,
					getState,
				)
				publishAccessibilityHint()(dispatch, getState)
			}

			const remainingCharactersText = formatMessage('input_chars_left', {
				number: definition.maxInputLength,
			})(dispatch, getState)

			validInput(remainingCharactersText)(dispatch, getState)
			showActionButton(ACTION_BUTTON_STATE.SKIP_READY, skipButtonText)(dispatch, getState)
		}
	} else {
		showActionHint(hintText)(dispatch, getState)
	}
}

// ------------------------------------
// initialState
// ------------------------------------
export const initialState = {}

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {}

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]

	return handler ? handler(state, action) : state
}
